import React, { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import Lottie from 'react-lottie'
import { RiChatCheckLine } from 'react-icons/ri'
import { Button, ButtonType } from '../buttons'
import { Input } from '../inputs'
import { AnimatePresence, motion } from 'framer-motion'
import animationData from 'assets/animations/success-animation.json'
import { BodyText, H2 } from '../typography'
import { SEND_FEEDBACK } from 'queries/feedback'
import { LuExternalLink } from 'react-icons/lu'
import { Trans, useTranslation } from 'next-i18next'

interface FeedbackButtonProps {
  featureId: 'documents' | 'optout'
}

const FeedbackButton: React.FC<FeedbackButtonProps> = ({ featureId }) => {
  const { t } = useTranslation()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [rating, setRating] = useState<number | null>(null)
  const [review, setReview] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [sent, setSent] = useState(false)

  const pageUrl = typeof window !== 'undefined' ? window.location.href : ''

  const [sendFeedback] = useMutation(SEND_FEEDBACK)

  useEffect(() => {
    if (isModalOpen) {
      setRating(null)
      setReview('')
      setSent(false)
    }
  }, [isModalOpen])

  const ratingEmojis = ['😠', '😞', '😐', '🙂', '😃']

  const options = {
    animationData: animationData,
    loop: false,
    autoplay: true
  }

  const handleSend = async () => {
    if (rating === null) return
    setIsSubmitting(true)
    try {
      await sendFeedback({
        variables: {
          rating,
          pageUrl,
          featureId,
          review
        }
      })
      setRating(null)
      setReview('')
      setSent(true)
      setTimeout(() => {
        setIsModalOpen(false)
        setSent(false)
      }, 5000)
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <>
      <Button
        buttonType={ButtonType.Primary}
        style={{
          transformOrigin: 'bottom right',
          transform: 'rotate(-90deg) translateX(95px)'
        }}
        className="!fixed right-0 !h-[36px] top-1/2 bg-purple text-white active:bg-purple-500 whitespace-nowrap flex gap-2 !font-normal"
        onClick={() => setIsModalOpen(prev => !prev)}
      >
        <RiChatCheckLine className="mr-2" />
        {t('feedback.button')}
      </Button>
      <AnimatePresence>
        {isModalOpen && (
          <motion.div
            initial={{ scale: 0, y: '-50%' }}
            animate={{ scale: 1, y: '-50%' }}
            exit={{ scale: 0, y: '-50%' }}
            className="fixed right-14 z-50 top-1/2 bg-white shadow-lg dark:bg-white rounded-lg w-full max-w-md mx-auto overflow-hidden"
          >
            {sent ? (
              <div className="px-6 py-6 ">
                <Lottie width={100} options={options}></Lottie>
                <div className="space-y-1 mt-6">
                  <H2 className="text-center !text-[#000] !dark:text-[#000] !font-bold">
                    <Trans
                      components={{
                        bold: <span className="text-primary"></span>
                      }}
                      i18nKey={'feedback.success.title'}
                    ></Trans>
                  </H2>
                  <BodyText className="text-center !text-[#000] !dark:text-[#000]">
                    {t('feedback.success.description')}
                  </BodyText>
                </div>
              </div>
            ) : (
              <>
                <div className="bg-purple py-4 text-center">
                  <H2 className="!font-bold  text-white">
                    {t(`feedback.${featureId}`)}
                  </H2>
                </div>
                <div className="px-6 py-6 space-y-4">
                  <div className="flex justify-between max-w-200 mx-auto">
                    {ratingEmojis.map((emoji, index) => (
                      <button
                        key={index}
                        onClick={() => setRating(index + 1)}
                        className={`text-3xl ${
                          rating && rating === index + 1
                            ? 'opacity-100'
                            : 'opacity-50'
                        }`}
                      >
                        <span>{emoji}</span>
                      </button>
                    ))}
                  </div>
                  <Input
                    classes="dark:bg-white !text-[#000] !dark:text-[#000]"
                    type="textarea"
                    placeholder={t(`feedback.placeholder`)}
                    value={review}
                    events={{
                      onChange: (e: { value: string }) => {
                        setReview(e.value)
                      }
                    }}
                  />
                  <div className="flex justify-center space-x-4">
                    <Button
                      buttonType={ButtonType.Secondary}
                      onClick={() => setIsModalOpen(false)}
                      className="dark:text-[#000] dark:hover:text-[#000] dark:hover:bg-gray-75 dark:bg-gray-75 dark:active:bg-white-300"
                    >
                      {t(`feedback.cancel`)}
                    </Button>
                    <Button
                      buttonType={ButtonType.Primary}
                      onClick={handleSend}
                      loading={isSubmitting}
                      iconLeft={LuExternalLink}
                      className="bg-purple active:bg-purple-400 text-white"
                    >
                      {t(`feedback.send`)}
                    </Button>
                  </div>
                </div>
              </>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}

export default FeedbackButton
