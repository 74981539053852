import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'

import { RiChat4Line, RiChatCheckLine } from 'react-icons/ri'
import { CgFileDocument } from 'react-icons/cg'
import { IoExitOutline } from 'react-icons/io5'
import { VscKey } from 'react-icons/vsc'
import { DiCode } from 'react-icons/di'
import { FiSettings, FiUsers } from 'react-icons/fi'
import { HiOutlineClipboardDocumentList } from 'react-icons/hi2'

export const usePageSectionTabs = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const { organizationPathname, disclaimerPathname } = router.query

  const organizationTabs = [
    {
      name: t('org.pageSection.disclaimers'),
      pathname: `/org/${organizationPathname}/disclaimers`,
      icon: <RiChat4Line size={18} />,
      page: 'disclaimers'
    },
    {
      name: t('org.pageSection.documents'),
      pathname: `/org/${organizationPathname}/documents`,
      icon: <HiOutlineClipboardDocumentList size={18} />,
      page: 'documents'
    },
    {
      name: t('org.pageSection.members'),
      pathname: `/org/${organizationPathname}/members`,
      icon: <FiUsers size={16} />,
      page: 'members'
    },
    {
      name: t('org.pageSection.settings'),
      pathname: `/org/${organizationPathname}/settings`,
      icon: <FiSettings size={16} />,
      page: 'settings'
    }
  ]

  const disclaimerTabs = [
    {
      name: t('disclaimer.pageSection.style'),
      pathname: `/org/${organizationPathname}/disclaimer/${disclaimerPathname}/style`,
      icon: <RiChatCheckLine size={18} />,
      page: 'style'
    },
    {
      name: t('disclaimer.pageSection.documents'),
      pathname: `/org/${organizationPathname}/disclaimer/${disclaimerPathname}/documents`,
      icon: <CgFileDocument size={18} />,
      page: 'documents'
    },
    {
      name: t('disclaimer.pageSection.optOut'),
      pathname: `/org/${organizationPathname}/disclaimer/${disclaimerPathname}/opt-out`,
      icon: <IoExitOutline size={18} />,
      page: 'optOut'
    },
    {
      name: t('disclaimer.pageSection.installation'),
      pathname: `/org/${organizationPathname}/disclaimer/${disclaimerPathname}/installation`,
      icon: <VscKey size={18} />,
      page: 'installation'
    },
    {
      name: t('disclaimer.pageSection.tags'),
      pathname: `/org/${organizationPathname}/disclaimer/${disclaimerPathname}/tags`,
      icon: <DiCode size={18} />,
      page: 'tags'
    },
    {
      name: t('disclaimer.pageSection.settings'),
      pathname: `/org/${organizationPathname}/disclaimer/${disclaimerPathname}`,
      icon: <FiSettings size={18} />,
      page: 'settings'
    }
  ]

  return { organizationTabs, disclaimerTabs }
}
